import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import type { LoaderFunctionArgs, MetaFunction } from '@remix-run/node'
import { useLoaderData } from '@remix-run/react'
import { motion } from 'framer-motion'
import Container from '~/components/Container'
import Footer from '~/components/Footer'
import LinkButton from '~/components/LinkButton'
import MarqueePress from '~/components/Marquees/MarqueePress'
import MouseScroll from '~/components/MouseScroll'
import Nav from '~/components/Nav'
import Typography from '~/components/Typography'
import { MEETING_SALES_URL } from '~/constants/links'
import {
  matchIsMaintenanceMode,
  matchIsServerSideLogged
} from '~/firebase.server'
import { useTrackPageView } from '~/hooks/useTrackPageView'
import { getFixedT } from '~/i18n/i18next.server'
import { buildMetas } from '~/utils/metas'
import { trackEvent } from '~/utils/tracking'
import BookASlot from './BookASlot'
import HomeCheckup from './HomeCheckup'
import HomeFollowUp from './HomeFollowup'
import MeetingImage from './meeting.jpg'

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const { t } = await getFixedT(request)

  const isLogged = await matchIsServerSideLogged(request)

  const isMaintenanceMode = await matchIsMaintenanceMode(request)

  return {
    title: t('common:metas.title.default'),
    isLogged,
    isMaintenanceMode
  }
}

export const meta: MetaFunction<typeof loader> = ({
  location,
  params,
  data
}) => {
  return buildMetas({
    location,
    params,
    title: data?.title
  })
}

export const handle = {
  i18n: ['home', 'booking']
}

const HomeRoute = () => {
  const { isLogged, isMaintenanceMode } = useLoaderData<typeof loader>()
  const contentAfterHero = React.useRef<HTMLDivElement>(null)

  const { t } = useTranslation(['home', 'booking', 'landing'])

  useTrackPageView('Home')

  const scrollToAboveTheFold = () => {
    contentAfterHero.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  }

  const trackHubspotMeetingClick = () => {
    trackEvent('HUBSPOT_MEETING_CLICK')
  }

  return (
    <>
      <Nav />
      <main>
        <div className="flex h-[calc(100vh-var(--nav-height))] w-full flex-col items-center justify-between">
          <div className="flex flex-col  w-full h-full items-center gap-y-10 justify-around bg-gold bg-landing-hero bg-no-repeat bg-[20%_top] bg-cover max-sm:bg-landing-hero-mobile max-sm:bg-center">
            <Container className="flex flex-col items-center gap-y-10">
              <Typography
                variant="h1"
                className="text-center text-7xl font-normal uppercase max-lg:text-6xl max-md:text-5xl max-sm:text-4xl text-white"
                family="brand"
                id="hero-title"
              >
                <motion.span
                  className="block"
                  initial={{ opacity: 0, scale: 1.2 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <Trans i18nKey="home:headline" />
                </motion.span>
              </Typography>
              {isMaintenanceMode ? null : (
                <motion.div
                  initial={{ opacity: 0, y: 5 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3, delay: 0.4 }}
                >
                  <LinkButton
                    rounded="full"
                    size="lg"
                    to="#book-a-slot"
                    testId="slotBooking"
                    color="secondary"
                  >
                    {t('common:book')}
                  </LinkButton>
                </motion.div>
              )}
            </Container>
            <button
              type="button"
              onClick={scrollToAboveTheFold}
              aria-label={t('common:seeNextContent')}
              className="absolute bottom-2 flex cursor-pointer flex-col items-center gap-y-2 p-2 max-sm:hidden"
              data-testid="mouseScroll"
            >
              <MouseScroll className="text-white/90" />
            </button>
          </div>
        </div>
        <HomeCheckup ref={contentAfterHero} />
        <HomeFollowUp />
        {isMaintenanceMode ? null : <BookASlot isLogged={isLogged} />}
        <div className="grid grid-cols-1 lg:grid-cols-2 sm:rounded-lg md:grid-cols-2 overflow-hidden max-w-[90%] md:max-w-[930px] max-sm:max-w-[100%] shadow-landing mt-6 mx-auto">
          <div className="flex items-center justify-center max-h-[330px] overflow-hidden">
            <img
              className="object-cover"
              loading="lazy"
              src={MeetingImage}
              alt={t('landing:medecine.echange.title')}
            />
          </div>
          <div className="flex items-center p-10">
            <div className="flex flex-col gap-y-6 justify-center">
              <Typography
                className="text-black text-2xl text-center"
                variant="h3"
              >
                {t('landing:medecine.echange.title')}
              </Typography>
              <Typography variant="body2" color="shy" align="center">
                {t('landing:medecine.echange.subtitle')}
              </Typography>
              <LinkButton
                to={MEETING_SALES_URL}
                target="_blank"
                testId="hubspotMeetingButton"
                rounded="full"
                onClick={trackHubspotMeetingClick}
                className="mx-auto"
              >
                {t('landing:medecine.echange.cta')}
              </LinkButton>
            </div>
          </div>
        </div>
        <MarqueePress className="mx-auto my-16 max-w-screen-2xl" />
      </main>
      <Footer />
    </>
  )
}

export default HomeRoute
